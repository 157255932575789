import React from "react";
import "./Homepage.css";
import Waves from "../components/Waves";
import About from "../components/About";
import FlippedWaves from "../components/FlippedWaves";
import Education from "../components/Eduction";

function Homepage() {
  return (
    <div>
      <Waves></Waves>
      <About></About>
      <FlippedWaves></FlippedWaves>
      <Education></Education>
      <div className="mt-80"></div>
    </div>
  );
}

export default Homepage;
