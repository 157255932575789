import "./Components.css";
import { Button, IconButton } from "@material-tailwind/react";

function About() {
  return (
    <div className="py-32 bg">
      <div className="bg-gray-900 mx-[8%] rounded-xl h-[500px] px-10 pt-8">
        <div className="text-zinc-400 ysabeau-sc font-medium">Introduction</div>
        <div className="grid grid-cols-10 font-sans font-semibold text-md">
          <div className="col-span-4">
            <img
              src="../Headshot.png"
              alt=""
              className="rounded-[100%] h-[60px] w-[60px] bg-zinc-500"
            />
            <div className="grid grid-cols-4">
              <div></div>
              <div>
                <button className="bg-cyan-500 p-1.5 rounded-lg text-left">
                  Resume
                </button>
              </div>
              <div>
                <button className="bg-cyan-500 p-1.5 rounded-lg text-right">
                  LinkedIn
                </button>
              </div>
              <div></div>
            </div>
            <div className="grid grid-cols-3">
              <div></div>
              <div className="">
                <button className="bg-cyan-500 p-1.5 rounded-lg ml-6 mt-6">
                  GitHub
                </button>
              </div>
              <div></div>
            </div>
          </div>
          <div className="col-span-6 text-zinc-400">
            <div className="space-y-6 my-[12%]">
              <div className="">
                I am a Fullstack Developer with 2 years of professional
                experience in software development.
              </div>
              <div className="">
                Currently working towards my Honors Bachelor of Science in
                Computer Information Systems at the University Of Texas At
                Tyler.
              </div>
              <div className="">
                I enjoy developing complex software and solving difficult
                problems.
              </div>
              <div className="">
                I am always curious and constantly learning.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
